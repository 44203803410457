import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  BrowserRouter as Router
} from "react-router-dom";
import "./index.css";
import "../node_modules/react-widgets/dist/css/react-widgets.css"; // TODO: Handle this import in build.
import "./styles/font-awesome/css/fontawesome-all.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "core-js/es7";
import App from "./App";

import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import ScrollToTop from "./ScrollToTop";

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";

import { reducer as formReducer } from "redux-form";
import auth from "./auth/reducer";

import register from "./containers/User/Register/reducer";
import menu from "./components/Menu/reducer";
import group from "./containers/MyPages/VolunteerContext/reducer";
import event from "./containers/MyPages/Event/reducer";
import forgotPassword from "./containers/User/ForgotPassword/reducer";
import start from "./containers/Start/reducer";
import groupAvailabilities from "./containers/MyPages/VolunteerContext/GroupAvailability/reducer";
import user from "./containers/User/reducer";
import sendOuts from "./containers/MyPages/Event/SendOuts/reducer";
import attendance from "./containers/MyPages/Event/Attendance/reducer";
import teamleader from "./containers/MyPages/TeamLeader/reducer";
import selfRegistration from "./containers/SelfRegistration/reducer";

import { watcherSaga } from "./sagas";
import labels from "./labels.json";
import { IntlProvider } from "react-intl";

import { initAxios } from "./setup/api";

import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import offers from "./containers/MyPages/Offer/reducer";
// import mapStateToProps from 'react-redux/lib/connect/mapStateToProps';

import Amplify from 'aws-amplify';
import awsConfig from './aws-config';
Amplify.configure(awsConfig);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// dev tools middleware
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// create a redux store with our reducer above and middleware
export const store = createStore(
  combineReducers({
    form: formReducer,
    auth,
    register,
    menu,
    group,
    event,
    forgotPassword,
    start,
    groupAvailabilities,
    offers,
    attendance,
    user: user,
    sendOuts,
    teamleader,
    selfRegistration,
  }),
  reduxDevTools ? compose(applyMiddleware(sagaMiddleware), reduxDevTools) : compose(applyMiddleware(sagaMiddleware))
  // applyMiddleware(sagaMiddleware)
);

initAxios();

// run the saga
sagaMiddleware.run(watcherSaga);

// React Widgets localization
moment.updateLocale('sv', {
  week : {
      dow : 1,
      doy : 4
   }
});
moment.locale("sv");
momentLocalizer();

class ToggleLabels extends Component {
  constructor(props) {
    super(props);

    this.state = { onlyKeys: false };
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keyDown);
    window.addEventListener("keyup", this.keyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("keyup", this.keyUp);
  }

  keyDown(val) {
    if (val.keyCode === 16 && val.altKey && val.ctrlKey) {
      this.setState({ onlyKeys: !this.state.onlyKeys });
    }
  }

  keyUp(val) {
    if (val.keyCode === 16 && this.state.onlyKeys) {
      this.setState({ onlyKeys: !this.state.onlyKeys });
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.state.onlyKeys ? "fluff" : "en"}
        messages={this.state.onlyKeys ? {} : this.props.selectedLanguage === 1 ? labels.Swedish : labels.English}
      >
        <Router>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  selectedLanguage: state.menu.selectedLanguage,
});

const ConnectedToggleLabels = connect(mapStateToProps)(ToggleLabels)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedToggleLabels />
  </Provider>,
  document.getElementById("root")
);


unregister();
