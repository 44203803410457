import React, { Component } from "react";
import styled from "styled-components";
import { H2, WhiteTableWithLastToRight, WhiteTable, TableStatus, TableRowWithClick, P, TableRow } from "../../../../components/index";
import { TableButton, ToggleButton } from "../../../../components/Buttons/index";
import { breakPoints } from "../../../../constants/styling-constants";
import messages from "../messages";
import eventMessages from "../../Event/messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Wrapper = styled.div`
  margin-top: 90px;
  margin-bottom: 85px;
`;

const Table = styled(WhiteTable)`
  @media (max-width: ${breakPoints.sm}px) {
    .table-button-col {
      display: none;
    }
    .table-status-col {
      display: none;
    }
  }
`;

const TableRowHeader = TableRow.extend`
  th {
    background: transparent;
    font-weight: bold;
  }
`;

class TeamLeaderList extends Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.togglePreviousEvents = this.togglePreviousEvents.bind(this);

    this.state = {
      sortField: "date",
      sortDirection: -1,
      pastEvents: false
    }
  }

  componentDidMount() {
    this.props.fetchTeamleaderEvents();
  }

  handleRowClick(row) {
    this.props.history.push(`/my-pages/teamleaders/${row.id}`);
  }

  getDate(date) {
    return date.getFullYear() + "-" + (date.getMonth()<9 ? "0" : "") + (date.getMonth()+1)  + "-" + (date.getDate() <10 ? "0" : "") + date.getDate();
  }

  togglePreviousEvents() {
    this.setState({pastEvents: !this.state.pastEvents})
  }

  renderStatus(status) {
    switch (status) {
      case "CONFIRMED":
        return (<TableStatus><FormattedMessage {...messages.list.confirmed} /></TableStatus>);
      case "HISTORIC":
        return (<TableStatus><FormattedMessage {...eventMessages.statusHistoric} /></TableStatus>);
      case "DRAFT":
      default:
        return (<TableStatus className="draft"><FormattedMessage {...messages.list.draft} /></TableStatus>);
    }
  }

  renderTableHeader() {
    return (
      <TableRowHeader>
        <th onClick={() => this.state.sortField === "name" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"name"})}><FormattedMessage {...eventMessages.myEvents.header.eventTitle} /></th>
        <th className={"right "} onClick={() => this.state.sortField === "date" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"date"})}><FormattedMessage {...eventMessages.myEvents.header.eventDate} /></th>
        <th className={'table-status-col'} onClick={() => this.state.sortField === "status" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"status"})}><FormattedMessage {...eventMessages.myEvents.header.eventStatus} /></th>
        <th className="hidden-xs"></th>
      </TableRowHeader>
      )
  }

  renderRow(row) {
    return (
      <TableRowWithClick
        key={row.id}
        row={row}
        callback={this.handleRowClick}
      >
        <td>{row.name}</td>
        <td className={"right "}>{row.date ? this.getDate(new Date(row.date))  : ""}</td>
        <td className={'table-status-col'}>{this.renderStatus(row.status)}</td>
        <td className="hidden-xs"><TableButton label={this.props.intl.formatMessage(messages.list.showButton)} /></td>
      </TableRowWithClick>
    );
  }

  renderTable(events, past=false) {
    if(events.length === 0) {
      // User has no events, show no events message
      return (
        <Table>
          <tbody>
            <TableRow>
              <td><FormattedMessage {...messages.list.noEvents} /></td>
            </TableRow>
          </tbody>
        </Table>
      );
    }
    return (
      <Table>
        <tbody>
          {this.renderTableHeader()}
          {events.sort((a,b) => a[this.state.sortField] ? -1 : 1).sort((a, b) => a && b && a[this.state.sortField] && b[this.state.sortField] ? this.state.sortDirection * a[this.state.sortField].localeCompare(b[this.state.sortField]) : 0).map(e => this.renderRow(e, past))}
        </tbody>
      </Table>
    );
  }

  render() {
    
    const eventList = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => !event.date || event.status == "DRAFT" ? false : new Date(event.date) > new Date().setDate(new Date().getDate() - 7)) : null;
    const pastList = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => event.status == "DRAFT" ? false : !eventList.some(e => e.id === event.id)) : null;

    return (
      <Wrapper>
        <H2><FormattedMessage {...messages.list.title} /></H2>
        {(this.props.eventsSummaries) ? this.renderTable(eventList) : <i className="fas fa-spinner fa-pulse" />}
        {(this.props.eventsSummaries) ? <ToggleButton icon={this.state.pastEvents ? "fas fa-chevron-down" : "fas fa-chevron-up"} callback={this.togglePreviousEvents} label={this.props.intl.formatMessage(eventMessages.myEvents.showPrevious)} /> : ""}
        {(pastList && this.state.pastEvents) ? this.renderTable(pastList, true) : ""}
      </Wrapper>
    );
  }
}

export default injectIntl(TeamLeaderList);
