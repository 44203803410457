import "./index.css";
import React, {Component} from 'react';
import { withRouter } from 'react-router';
import {FormattedMessage, injectIntl} from 'react-intl';

import { connect } from 'react-redux'
import { reset } from 'redux-form';
import { status } from './reducer';

import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap/lib';

import moment from 'moment';
import messages from './messages';

import { H2, TextSection } from '../../../../components/index';
import { DeleteButton, SmallGrayButton, removeMargin } from '../../../../components/Buttons';
import { ModalWrapper, Content } from '../../../../components/ConfirmationModal';
import { municipalities } from '../../../../constants/municipalities';
import AddAvailabilityForm from './AddGroupAvailabilityForm'
import styled from "styled-components";

const SmallThinGrayButton = removeMargin(SmallGrayButton);

class GroupAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      products: [],
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.addGroupAvailability = this.addGroupAvailability.bind(this);
    this.submitAvailabilities = this.submitAvailabilities.bind(this);

  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps !== this.props){
    const { initialValues: { availabilities = [] } = {}, intl, publicEvents, fetchEvent, completeEvents, groupId, group } = this.props || {}
    const products = availabilities ? availabilities.map(availability => {
      const location = municipalities.find(municipality => municipality.id === availability.location) || {}
      switch (availability.type) {
        case "PERIOD":
          return {id: availability.organizerSK, type:  `${moment(availability.startDate).format("DD MMMM YYYY")} - ${moment(availability.endDate).format("DD MMMM YYYY")}`, location: location.name, button: <DeleteButton onClick={() => this.deleteGroupAvailibility(availability)} />}
        default: { //case "EVENT"
          const eventFound = (this.props && completeEvents) && (completeEvents.find(publicEvent => publicEvent.eventId === availability.eventId)) || fetchEvent(availability.eventId)
          return {id : availability.organizerSK, type: eventFound.name || <FormattedMessage id="common.loading.message" />, location: location.name, button: <DeleteButton onClick={() => this.deleteGroupAvailibility(availability)} />}
        }
      }
    }) : []
    this.setState({products});
  }}

  UNSAFE_componentWillMount () {
    const { fetchPublicEvents, groupId, fetchGroupAvailabilities } = this.props || {};
    fetchPublicEvents({startDate: moment().format('YYYY-MM-DD'), endDate: moment().add(3, 'years').format('YYYY-MM-DD')});
    groupId && fetchGroupAvailabilities({ groupId: groupId })
  }

  toggleModal() {
    const { resetForm } = this.props || {};
    resetForm()
    this.setState({
      showModal: !this.state.showModal
    });
  }

  addGroupAvailability(data) {
    const { initialValues: { availabilities = [] } = {}, groupId, submitGroupAvailabilities } = this.props || {}
    const newAvailabilities = [...availabilities]
    newAvailabilities.push({...data})
    const toSubmit = {
      groupId: groupId,
      availabilities: newAvailabilities,
    };
    submitGroupAvailabilities(toSubmit);
    this.toggleModal()

  }

  deleteGroupAvailibility(data) {
    const { initialValues: { availabilities = [] } = {} } = this.props || {}
    const newAvailabities = availabilities.filter(availability => 
      !(availability.organizerSK === data.organizerSK && 
        availability.organizerPK === data.organizerPK &&
        availability.groupSK === data.groupSK &&
        availability.location === data.location &&
        availability.type === data.type   
    ))
    this.submitAvailabilities(newAvailabities)
  }

  submitAvailabilities(data) {
    const { availabilityStatus, groupId, submitGroupAvailabilities } = this.props || {};
    // Prevent double click by not saving when request is ongoing
    if (availabilityStatus === status.SAVING) {
      return;
    }
    const toSubmit = {
      groupId: groupId,
      availabilities: data,
    };
    submitGroupAvailabilities(toSubmit);
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
      }, 
      {
        dataField: "type",
        text: this.props.intl.formatMessage(messages.table.event),
        sort: true,
      }, 
      {
        dataField: "location",
        text: this.props.intl.formatMessage(messages.table.location),
        sort: true,
      },
      {
        dataField: "button",
        text: "",
        classes: "hidden-column",
        headerStyle: {backgroundColor: "#e2f0f1", borderWidth: 0}
      }
    ]

    const { initialValues: { availabilities = [] } = {}, intl, publicEvents, fetchEvent, completeEvents, groupId, group } = this.props || {}
    
    if (!groupId && !group.group.groupId) {
      return <H2>{'[Det ska inte vara tillåtet att gå hit utan att ha sparat gruppen eftersom vi inte har något grupp-id att referera till. Går ju såklart att bygga men det ger ökad komplexitet så det är ett hörn som vi bör kutta tillsvidare.]'}</H2>;
    }

    return (
      <TextSection>
        <H2><FormattedMessage {...messages.title} /></H2>
        {this.state.products.length > 0 && (
          <BootstrapTable keyField='id' data={this.state.products} columns={columns} classes="table-test" wrapperClasses="responsive-table" />
        )}
        <SmallThinGrayButton type="button" onClick={() => {
          this.setState({showModal: !this.state.showModal})
        }}>
          <FormattedMessage {...messages.table.newRow} />
        </SmallThinGrayButton>
        <AddInterestModal 
          show={this.state.showModal}
          close={this.toggleModal}
          intl={intl}
          title={intl.formatMessage(messages.addForm.title)}
          form={
          <AddAvailabilityForm
            events={publicEvents}
            fetchEvent={fetchEvent}
            completeEvents={completeEvents}
            onSubmit={this.addGroupAvailability}
          />}
        />
      </TextSection>
    );
  }
}

const SmallDeleteButton = styled(DeleteButton)`
  display: block;
  margin-left: auto;
  margin-right: 0;
  i {
    font-size: 30px;
    color: gray;
  }
`;

const NoPaddingModalBody = styled(Modal.Body)`
  padding: 0px;
`;

const AddInterestModal = ({ show, close, form, title }) => (
  <ModalWrapper show={show} onHide={close}>
    <NoPaddingModalBody>
      <Content>
        <div style={{display: "flex"}}> 
          <H2>{title}</H2>
          <SmallDeleteButton onClick={close}/>
        </div>
        {form}
      </Content>
    </NoPaddingModalBody>
  </ModalWrapper>
)

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset("addInterestForm"))
})

const GroupAvailabilityForm = withRouter(connect(null, mapDispatchToProps)((injectIntl(GroupAvailability))))

export default GroupAvailabilityForm;
